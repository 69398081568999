button.ui.button,
button.ui.basic.button {
  @apply rounded-xl;
  font-family: 'Cairo';
}
button.ui.button.primary {
    @apply bg-primary hover:bg-primary focus:bg-primary active:bg-primary;
    font-family: 'Cairo';
  }
  
  button.ui.basic.primary.button,
  button.ui.basic.primary.active.button,
  button.ui.basic.primary.buttons.active.button,
  button.ui.basic.primary.button:hover,
  button.ui.basic.primary.buttons.button:hover {
    @apply ring-1 ring-primary text-primary !important;
    font-family: 'Cairo';
  }