.ui.pagination.menu .active.item {
    @apply bg-primary text-white rounded-lg font-extrabold border-none shadow-none;
}
.ui.mini.menu {
    @apply border-none font-extrabold shadow-none;
}
.ui.menu .item:before {
    @apply border-none font-extrabold shadow-none;
}
.ui.menu:not(.vertical) .item {
    @apply border-none font-extrabold shadow-none;
}
.ui.menu .item {
 @apply static;
}
.ui.menu a.item:hover {
    @apply rounded-lg
}