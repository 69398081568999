.ui.form .field.field input:-webkit-autofill:focus {
    @apply border-[#177BA3A5] bg-none bg-white shadow-none !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    font-family: 'Cairo';
  }

.ui.form .field.field input:-webkit-autofill {
    @apply border-[#177BA3A5] bg-none bg-white shadow-none !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
     font-family: 'Cairo';
}
.ui.form .field .ui.input input{
  font-family: 'Cairo';
  @apply border-[#177BA3A5];
}
.ui.form input[type="text"]:focus, .ui.search.selection.dropdown > input.search {
  @apply border-[#177BA3A5];
}
.ui.input > input,.ui.input > input:active,.ui.input > input:focus {
  font-family: 'Cairo';
  /* @apply bg-[#FCFCFC] rounded-full ring-1 ring-[#EDE8FC] border-none shadow-sm; */
}
.ui.form .field.error input[type="text"]{
  @apply border-[#e0b4b4] !important
}