@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400&family=El+Messiri:wght@400;500;600;700&display=swap');
body {
  font-family: 'Cairo';
  @apply bg-[#FCFCFC]
}
.ui.small.modal > .header:not(.ui) {
  font-family: 'Cairo';
}
.ui.menu .item:before{
  @apply bg-transparent;
}