h1 {
    @apply text-3xl font-semibold;
    font-family: 'Cairo';
}
  
h2 {
    @apply text-2xl font-semibold;
    font-family: 'Cairo';
}
  
h3 {
    @apply text-xl font-semibold;
    font-family: 'Cairo';
}

h4 {
    @apply text-lg font-semibold;
    font-family: 'Cairo';
}